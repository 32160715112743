<template>
  <div>
    <q-tabs 
      v-model="nodeTab" 
      shrink stretch dense
      align="left">
      <div class="q-pl-sm q-pr-sm" style="background:#B2DFDB;color:#00695C;height:100%;display:flex;align-items:center;">
        <b>
          <q-icon name="sms" size="xs"/>
          <!-- 노드 -->
          {{$label('LBL0001004')}}
          </b>
      </div>
      <keep-alive
        v-for="(tab, idx) in tabItems"
        :key="idx" >
        <q-tab
          :name="tab.name"
          :label="tab.label"
          v-on:click="tabClick(tab)">
        </q-tab>
      </keep-alive>
    </q-tabs>
    <div class="row">
      <div class="col-12">
        <q-list bordered class="rounded-borders">
          <!-- 노드 정보 -->
          <q-expansion-item 
            dense dark default-opened
            icon="place" 
            header-class="bg-blue-grey-6 text-white"
            expand-icon-class="text-white"
            :label="$label('LBL0001195')">
            <q-separator />
            <q-card class="q-pa-sm">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <c-label-text title="평가명" :value="assessPlan.assessmentName"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <c-label-text title="도면번호 / 도면명" :value="draw.diagramNo+' / '+draw.diagramTitle"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <c-label-text title="노드번호 / 노드명" :value="node.nodeNo+' / '+node.nodeTitle"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <!-- 검토기간 -->
                  <c-label-text title="LBL0001088" :value="node.startStudyDate + '~' + node.endStudyDate"></c-label-text>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <!-- 노드설명 -->
                  <c-label-text title="LBL0001085" :value="node.nodeDesc"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <!-- 설계조건 -->
                  <c-label-text title="LBL0001086" :value="node.designConditions"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <!-- 작동조건 -->
                  <c-label-text title="LBL0001087" :value="node.operatingConditions"></c-label-text>
                </div>
                  <!-- 설계의도 -->
                <div class="col-xs-12 col-sm-12 col-md-3">
                  <c-label-text title="LBL0001015" :value="node.designIntent"></c-label-text>
                </div>
              </div>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="node.scenarios"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      :customTrClass="setTrClass"
      selection="multiple"
      rowKey="ramKpsrAssessScenarioId"
      @innerBtnClicked="innerBtnClicked"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 이전평가결과조회 -->
          <c-btn 
            v-show="isCompared" 
            label="LBL0001166" 
            icon="compare_arrows" 
            @btnClicked="compareScenario" />
            <!-- 위험형태 추가 -->
          <c-btn v-if="editable&&!disabled" label="LBL0001199" icon="add" @btnClicked="addRiskType" />
            <!-- 삭제 -->
          <c-btn v-if="editable&&!disabled&&node.scenarios&&node.scenarios.length>0" 
            label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
          <c-btn 
            v-if="editable&&!disabled&&node.scenarios&&node.scenarios.length>0" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="node.scenarios"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveScenario"
            @btnCallback="saveScenarioCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable && Boolean(props.row.ramKpsrAssessScenarioId)"
            :requestContentsCols="requestContentsCols"
            tableKey="ramKpsrAssessScenarioId"
            ibmTaskTypeCd="ITT0000005"
            ibmTaskUnderTypeCd="ITTU000005"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'kpsr-scenario',

  props: {
    draw: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정 코드
        psiDiagramId: '',  // 도면 일련번호
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP, K-PSR 구분(평가계획의 평가기법 코드 그대로 사용)
        diagramNo: '',  // 도면번호
        diagramTitle: '',  // 도면명
        nodes: [],
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        draws: [],
        reCheckedCnt: 0,
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        title: '평가',
        flag: false,
        research: '',
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN',
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      nodeTab: '',
      node: {
        ramAssessNodeId: '',  // 노드 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP, K-PSR 구분(평가계획의 평가기법 코드 그대로 사용)
        processCd: '',  // 단위공정 코드
        psiDiagramId: '',  // 도면 일련번호
        nodeNo: '',  // 노드 번호_수기입력
        nodeTitle: '',  // 노드 타이틀
        nodeDesc: '',  // 노드 설명
        designConditions: '',  // 설계조건
        operatingConditions: '',  // 작동조건
        designIntent: '',  // 설계의도
        startStudyDate: '',  // 검토 시작일
        endStudyDate: '',  // 검토 종료일
        scenarios: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'riskTypName' },
        ],
        columns: [
          {
            fix: true,
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: 'LBL0001026',
            align: 'center',
            style: 'width:100px',
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', value: '1', tooltip: '위험형태별 원인 추가 <font color="#F0F4C3"><b>(※ 관리되는 항목)</b></font>' },
              { label: '', icon: 'add', color: 'orange', value: '2', tooltip: '위험형태별 원인 추가' },
            ],
            sortable: false,
          },
          {
            fix: true,
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            fix: true,
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'relationProblem',
            field: 'relationProblem',
            // 관련문제사항
            label: 'LBL0001024',
            align: 'left',
            type: 'text',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            type: 'date',
            style: 'width:130px',
            sortable: false,
            required: true,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
            required: true,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: 'LBL0001171',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            disableTarget: 'riskRegisterFlag',
            disableCon: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvementMeasures',
                field: 'improvementMeasures',
                // 개선대책
                label: 'LBL0001408',
                align: 'left',
                style: 'width:200px',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: 'auto' //'480px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      activeWinProps: {
        processCd: '',
        ramKpsrAssessScenarioId: '',
      },
      requestImprRow: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      rowIndex: 0,
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        assessmentName: '',  // 평가명
      },
    };
  },
  computed: {
    isCompared() {
      return this.process.reCheckedCnt > 0;
    },
    tabItems() {
      let data = [];
      this.$_.forEach(this.draw.nodes, node => {
        data.push({
          name: node.ramAssessNodeId,
          label: node.nodeTitle + ' (' + node.nodeNo + ')',
          node: node,
        })
      })
      return data;
    },
    requestContentsCols() {
      return ['riskTypName', 'riskOccurrenceCause'];
    }
  },
  watch: {
    'updateBtnData.research'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.kpsr.scenario.list.url
      this.saveUrl = transactionConfig.ram.kpsr.scenario.save.url
      this.deleteUrl = transactionConfig.ram.kpsr.scenario.delete.url
      this.detailUrl = selectConfig.ram.assessPlan.get.url;
      // code setting
      // list setting
      this.nodeTab = this.draw.nodes[0].ramAssessNodeId
      this.tabClick({ node: this.draw.nodes[0] })

      this.$http.url = this.$format(this.detailUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.assessPlan, _result.data);
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramAssessNodeId: this.node.ramAssessNodeId,
      }
      this.$http.request((_result) => {
        this.node.scenarios = _result.data;
        let idx = this.$_.findIndex(this.draw.nodes, { ramAssessNodeId: this.node.ramAssessNodeId });
        this.$set(this.draw.nodes[idx], 'scenarios', _result.data)
      },);
    },
    research() {
      this.$emit('research');
    },
    tabClick(tab) {
      this.$_.extend(this.node, tab.node)
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    innerBtnClicked(col, props, btn) {
      if (col.name === 'riskTypName') {
        if (btn.value === '1') {
          this.rowIndex = props.rowIndex;
          this.popupOptions.title = 'LBL0001200'; // 위험형태별 원인
          this.popupOptions.param = {
            ramRiskTypeId: props.row.ramRiskTypeId,
            riskTypName: props.row.riskTypName,
          };
          this.popupOptions.target = () => import(`${'@/pages/ram/base/kpsrCausePop.vue'}`);
          this.popupOptions.width = '60%';
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closeCausePopup;
        } else {
          this.node.scenarios.splice(props.rowIndex, 0, {
            ramKpsrAssessScenarioId: uid(),
            ramAssessNodeId: this.node.ramAssessNodeId,
            ramRiskTypeId: props.row.ramRiskTypeId, 
            riskTypName: props.row.riskTypName, 
            riskOccurrenceCause: '', 
            riskOccurrenceResult: '',
            relationProblem: '', 
            currentSafetyMeasures: '', 
            assessDate: this.$comm.getToday(),
            assessUserName: this.$_.clone(this.process.assessUserName),
            assessUserId: this.$_.clone(this.process.assessUserId),
            riskbookFlag: 'N', 
            sopImprovementIds: '', 
            ibmStepNames: '', 
            improvementMeasures: '', 
            editFlag: 'C',
            // 위험등록부 등재를 위한 prop
            ramRiskAssessmentPlanId: this.node.ramRiskAssessmentPlanId,
            processCd: this.node.processCd,
            riskRegisterFlag: false,
          });
        }
      }
    },
    closeCausePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.node.scenarios.splice(this.rowIndex, 0, {
            ramKpsrAssessScenarioId: uid(),
            ramAssessNodeId: this.node.ramAssessNodeId,
            ramRiskTypeId: item.ramRiskTypeId, 
            riskTypName: item.riskTypName, 
            riskOccurrenceCause: item.riskTypeCauseMainName + ' - ' + item.riskTypeCauseSubName, 
            riskOccurrenceResult: '',
            relationProblem: '', 
            currentSafetyMeasures: '', 
            assessDate: this.$comm.getToday(),
            assessUserName: this.$_.clone(this.process.assessUserName),
            assessUserId: this.$_.clone(this.process.assessUserId),
            riskbookFlag: 'N', 
            sopImprovementIds: '', 
            ibmStepNames: '', 
            improvementMeasures: '', 
            editFlag: 'C',
            // 위험등록부 등재를 위한 prop
            ramRiskAssessmentPlanId: this.node.ramRiskAssessmentPlanId,
            processCd: this.node.processCd,
            riskRegisterFlag: false,
          });
        });
      }
    },
    addRiskType() {
      this.popupOptions.title = 'LBL0001026'; // 위험형태
      this.popupOptions.param = {};
      this.popupOptions.target = () => import(`${'@/pages/ram/base/kpsrGuidewordPop.vue'}`);
      this.popupOptions.width = '20%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskTypePopup;
    },
    closeRiskTypePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.node.scenarios, { ramRiskTypeId: item.ramRiskTypeId }) === -1) {
            this.node.scenarios.splice(0, 0, {
              ramKpsrAssessScenarioId: uid(),
              ramAssessNodeId: this.node.ramAssessNodeId,
              ramRiskTypeId: item.ramRiskTypeId, 
              riskTypName: item.riskTypName, 
              riskOccurrenceCause: '', 
              riskOccurrenceResult: '',
              relationProblem: '', 
              currentSafetyMeasures: '', 
              assessDate: this.$comm.getToday(),
              assessUserName: this.$_.clone(this.process.assessUserName),
              assessUserId: this.$_.clone(this.process.assessUserId),
              riskbookFlag: 'N', 
              sopImprovementIds: '', 
              ibmStepNames: '', 
              improvementMeasures: '', 
              editFlag: 'C',
              // 위험등록부 등재를 위한 prop
              ramRiskAssessmentPlanId: this.node.ramRiskAssessmentPlanId,
              processCd: this.node.processCd,
              riskRegisterFlag: false,
            });
          }
        })
      }
    },
    saveScenario() {
      if (this.$comm.validTable(this.grid.columns, this.node.scenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.node.scenarios, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.node.scenarios.splice(this.$_.findIndex(this.node.scenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              this.planUpdateBtnData.planResearch = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}&psiDiagramId=${this.node.psiDiagramId}&nodeNo=${this.node.nodeNo}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramKpsrAssessScenarioId', recvObj.ramKpsrAssessScenarioId)
      }
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramKpsrAssessScenarioId ? 'bg-purple-2' : '';
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important
</style>